<template>
  <div class="about-side-bar">
    <div class="title">
      精英<br />风采
    </div>
    <div class="side-nav">
      <div v-for="(item, index) in navData" :key="index" class="item-box">
        <div @click="switchNav(item.class, null, index)" :class="[currentNav === item.class || currentParent === item.class ? 'active' : '',index + 1 === currentIndex ? 'activePrev' : '', 'nav-item']">
          <div>{{item.name}}</div>
          <img v-if="item.class == currentNav" src="../../assets/img/aboutus/more_active.png" alt="">
          <img v-else src="../../assets/img/aboutus/more.png" alt="">
        </div>
        <template v-if="currentParent === item.class" >
          <div v-for="(val, key) in item.children" :key="key"  class="secondary">
            <div @click="switchNav(val.class, item.class)" :class="[currentNav === val.class ? 'active' : '', val.name !='名人汇' ? 'activePrev' : '', 'nav-item']">
              <div>{{val.name}}</div>
              <img v-if="val.class == currentNav" src="../../assets/img/aboutus/more_active.png" alt="">
              <img v-else src="../../assets/img/aboutus/more.png" alt="">
            </div>
          </div>
        </template>        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentIndex: 0,
      currentNav: 'member',
      currentParent: 'hof',
      navData: [
        { name: '明亚名人堂', class: 'hof', children: [
          { name: '名人堂会员', class: 'member'},
          { name: '名人汇', class: 'club' }
        ] },
        { name: '明亚高峰会', class: 'meeting' },
        { name: '明亚精英', class: 'elite' },
        { name: '明亚生活', class: 'life' }
      ]
    }
  },
  created () {  
    // console.log(this.$route.name)  
    if (this.$route.name == 'eliteDetail') {
      let type = this.$route.query.type
      if (type == 'club') {
        this.currentNav = 'club'
        this.currentParent = 'hof'
      } else if (type == 'life') {
        this.currentNav = 'life'
      }
    } else {
      this.currentNav = this.$route.name
      this.currentParent = ''
    }
    if (this.$route.name == 'member' || this.$route.name == 'club') {
      this.currentParent = 'hof'
    }
    let _this = this
    this.navData.forEach((item, index) => {
      if (item.class == _this.currentNav) {
        _this.currentIndex = index
      }
    }) 
    // console.log(this.currentNav, this.currentParent)
    this.$emit('pageName', this.currentNav)
  },
  watch:{
    $route(to, from){
      if (to.name == 'eliteDetail') {
        let arr = from.path.split('/')
        let path = arr[arr.length - 1]
        if (arr[arr.length - 2] == 'hof') {
          this.currentParent = 'hof'
        }        
        this.currentNav = path
        if (to.query.type == 'club') {
          this.currentParent = 'hof'
          this.currentNav = 'club'
        } else if (to.query.type == 'life') {
          this.currentNav = 'life'
        }
      }
    }
  },
  methods: {
    // 导航切换
    switchNav (nav, parent, index) {
      this.currentIndex = index
      document.body.scrollTop=document.documentElement.scrollTop = 0
      if (this.currentNav != nav) {
        this.$emit('loading', true)
      }
      this.currentNav = nav
      this.currentParent = parent
      // this.$emit('loading', true)      
      if (parent) {
        this.$router.push({ path: '/eliteStyle/' + parent + '/' + nav })
      } else {
        if (nav == 'hof') {
          this.currentParent = 'hof'
          this.currentNav = 'member'
          this.$router.push({ path: '/eliteStyle/hof/member' })
        }
        this.$router.push({ path: '/eliteStyle/' + nav })
      }      
      this.$emit('pageName', nav)
    }
  }
}
</script>
<style lang="scss" scoped>
.about-side-bar {
  // position: fixed;
  // top: 5rem;
  width: 3rem;
  height: auto;
  margin-right: .57rem;
  .title {
    width: 100%;
    height: 1.1rem;
    background-image: url(../../assets/img/aboutus/nav.png);
    background-size: 100% 100%;
    font-size: 0.3rem;
    color: #ffffff;
    box-sizing: border-box;
    padding: .19rem .24rem 0;
    line-height: 1.3;
  }
  .side-nav {
    width: 100%;
    .secondary {
      box-sizing: border-box;
      padding-left: .2rem;   
      .nav-item {
        // padding-left: .4rem;
        &.active {
          background-color: #ffffff;
          &::after {
            display: none;
          }
        }
      }
    }
    .nav-item {
      background-color: #ffffff;
      width: 100%;
      display: flex;
      height: .55rem;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      box-sizing: border-box;
      padding: 0 0.2rem;
      border-bottom: 1px solid #DCDCDC;
      cursor: pointer;
      &.activePrev {
        border-bottom: 1px solid rgba(255, 168, 41, 0);
      }
      &:hover {
        background-color: #FFF8EC;
        color: #FF6004;
        border-color:rgba(255, 168, 41, .1);
      }
      &.active {
        background-color: #FFF8EC;
        color: #FF6004;
        border-color: rgba(255, 168, 41, .1);
        position: relative;
        &::after {
          display: block;
          content: "";
          height: 100%;
          width: 0.04rem;
          background-color: #FF6004;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    img {
      width: 0.06rem;
      height: 0.1rem;
    }
  }
}
</style>